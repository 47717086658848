import React from 'react'
import { BsHeart } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { ImYoutube2 } from "react-icons/im";
const Aboutpic = require("../../assets/aboutpic.png"); ;


const About = () => {
  return (
    <div>
      <div className="card">
        <img src={Aboutpic} alt="" className="card-img" />

        <div className="card-info">
          <p className="text-body">
            O Obejtivo deste blog é ajudar os professores a econtrar materiais
            que possam ajudar o maximo possivel seus alunos.
            <br />
            Aqui disponibilizamos pdfs com esses materias de maneira gratuita
          </p>
          <p className="text-title">
            Faça bom Proveito!! <BsHeart />
          </p>
        </div>
      </div>

      <div className="social-btns">
        <a
          className="btn google"
          href="https://www.instagram.com/pedagogiabh10/"
          target="__blank"
        >
          <AiOutlineInstagram className="fa fa-google"></AiOutlineInstagram>
        </a>

        <a
          className="btn google"
          href="https://www.youtube.com/channel/UC48vv7dJZicqMw20ncJtxqw"
        >
          <ImYoutube2 className="fa fa-google"> </ImYoutube2>
        </a>
      </div>
      
    </div>
  );
}

export default About