import React from 'react'
import './loading.css'

const loading = () => {
  return (
    <div className="spinner">
      <span>L</span>
      <span>O</span>
      <span>A</span>
      <span>D</span>
      <span>I</span>
      <span>N</span>
      <span>G</span>
    </div>
  );
}

export default loading