import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsPinAngle } from "react-icons/bs";
import "./MainPosts.css";
import { Link } from "react-router-dom";

const MainPosts = (posts) => {
  const [youtube, setYoutube] = useState("");

  const fetchYoutube = async () => {
    try {
      await axios
        .get("https://pedagogiabh10.herokuapp.com/get/youtube")
        .then((res) => setYoutube(res.data.urls[0].url));
      
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchYoutube();
  }, []);

  return (
    <>
      <div className="youtubevideo">
        <h1>
          Post em destaque <BsPinAngle />
        </h1>

        {youtube ? (
          <iframe
            src={youtube}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        ) : (
          <div></div>
        )}

        <h1>Venha conhecer mais sobre o mundo da pedagogia no nosso canal!</h1>
      </div>
      {posts.posts.reverse().map((item) => {
        return (
          <div className="PostsCard">
            <img className="PostsCardImg" src={item.img_url} alt="" />
            <h1 className="PostsCardTitle">{item.title}</h1>
            <p className="PostsCardDesc">{item.desc}</p>

            <button className="learn-more">
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <span className="button-text">
                <Link to={item.title}>Baixe essa Atividade</Link>
              </span>
            </button>
          </div>
        );
      })}
    </>
  );
};

export default MainPosts;
