import React, { useState, useEffect } from "react";
import axios from "axios";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/header";
import Main from "./components/Main/Main.jsx";
import { FullPosts } from "./components/FullPosts/FullPosts";
import About from "./components/About/About";
import Help from "./components/Contato/help";

function App() {
  const [posts, setPosts] = useState([]);
  const [loading, setloading] = useState(false);

  const fetchPosts = async () => {
    try {
      await axios
        .get("https://pedagogiabh10.herokuapp.com/get/posts")
        .then((res) => setPosts(res.data));
      setloading(true);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Main posts={posts} loading={loading} />
            </>
          }
        />

        <Route
          exact
          path="/:postname"
          element={
            <>
              <FullPosts posts={posts} />
            </>
          }
        />

        <Route
          exact
          path="/about"
          element={
            <>
              <About />
            </>
          }
        />

        <Route
          exact
          path="/help"
          element={
            <>
              <Help />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
