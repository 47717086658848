import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BsDownload } from "react-icons/bs";
import "./fullpost.css";

export const FullPosts = ({ posts }) => {
  let postDate;
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const params = useParams();
  const [atual, setAtual] = useState([]);

  const filter = () => {
    const atualPost = posts.filter((item) => item.title == params.postname);
    setAtual(atualPost);
  };

  useEffect(() => {
    filter();
  }, []);

  return (
    <>
      <div className="fullPost">
        {atual.map((item) => {
          postDate = new Date();

          return (
            <div className="fullPostBody">
              <h1 className="fullPostTitle">{item.title}</h1>
              <small>
                Por PedagogiaBH em{" "}
                {postDate.toLocaleDateString("pt-BR", options)}
              </small>
              <img src={item.img_url} className="fullPostImg" />
              <p className="fullPostDesc">{item.desc}</p>
              <br />
              <br />
              <h4>Para baixar essa atividade basta clicar no botão abaixo</h4>
              <a href={item.pdf_url} download>
                <button className="donwload">
                  <span>
                    {" "}
                    <BsDownload className="downloadicon" /> Baixe Agora!
                  </span>
                  <div class="top"></div>
                  <div class="left"></div>
                  <div class="bottom"></div>
                  <div class="right"></div>
                </button>
              </a>

              {/* Lembrar de substituir a url do video onde tem watch?v= por embed/ <iframe></iframe>  */}
            </div>
          );
        })}
      </div>
    </>
  );
};
