import React from 'react'
import { Link } from 'react-router-dom';
import {FaBars} from 'react-icons/fa'
import { AiOutlineClose } from "react-icons/ai";
import LOGO from "../../assets/finallogo.png";
import MobileLogo from "../../assets/logo.png";
import './header.css'

const header = () => {

  const openMenu = () =>{
    const menu = document.querySelector(".mobile-menu");
    const bars = document.querySelector(".bars");
    const close = document.querySelector(".close");

    menu.classList.toggle('open');
    bars.style.display = 'none';
    close.style.display = "block";
  }

  const closeMenu = () => {
    const menu = document.querySelector(".mobile-menu");
    const bars = document.querySelector(".bars");
    const close = document.querySelector(".close");

    menu.classList.toggle("open");
    bars.style.display = "block";
    close.style.display = "none";
  }


  return (
    <>
      <div className="mobile-menu">
        <Link to="about">
          <button className="aboutButton" onClick={closeMenu}>
            Sobre nós
          </button>
        </Link>
        <Link to="help">
          <button className="help" onClick={closeMenu}>
            Contato
          </button>
        </Link>
      </div>
      <div className="header">
        <nav>

          <Link to="/" className="logo">
           <img src={LOGO} alt="" />
           <img src={MobileLogo} alt="" className='mobileLogo' />
          </Link>

          <FaBars className="bars" onClick={openMenu} />
          <AiOutlineClose className="close" onClick={closeMenu} />
        </nav>
      </div>
    </>
  );
}

export default header