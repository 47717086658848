import React from "react";
import "./Main.css";
import Loading from './../Loading/loading';
import MainPosts from "../MainPosts/MainPosts";
import Aside from './../Aside/aside';



const Main = ({ posts, youtube, loading }) => {
  return (
    <>
      <div className="body">
        <div className="main">
          {loading ? (
            <MainPosts posts={posts} youtube={youtube} />
          ) : (
            <Loading />
          )}
        </div>

        <Aside />
      </div>
    </>
  );
};

export default Main;
